<template>
  <div id="site-map-component">
    <div id="site-map-content">
      <section id="address">
        <p>The Bridge Baptist Church</p>
        <p>Mansfield Road</p>
        <p>Sutton-in-Ashfield</p>
        <p>NG17 4FN</p>
        <br />
        <p>0758 056 4995</p>
      </section>
      <div id="divider-line"></div>
      <section id="links">
        <RouterLink class="secondary-button" to="/about">about</RouterLink>
        <RouterLink class="secondary-button" to="/service">service</RouterLink>
        <RouterLink class="secondary-button" to="/children">
          children
        </RouterLink>
        <RouterLink class="secondary-button" to="/midweek">midweek</RouterLink>
        <RouterLink class="secondary-button" to="/social">social</RouterLink>
        <RouterLink class="secondary-button" to="/community">
          community
        </RouterLink>
        <RouterLink class="secondary-button" to="/safeguarding">
          safeguarding
        </RouterLink>
        <RouterLink class="secondary-button" to="/membership">
          membership
        </RouterLink>
        <RouterLink class="secondary-button" to="/gallery">gallery</RouterLink>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "SiteMapComponent",
};
</script>

<style scoped>
#site-map-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--grey-4);
}

#site-map-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 3rem;
  width: 100%;
  max-width: 90rem;
}

#site-map-content p {
  color: var(--text-light);
}

#divider-line {
  height: 12rem;
  width: 0.5rem;
  background-color: var(--highlight);
  border-radius: 10rem;
}

#address {
  min-width: 13rem;
}

#links {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 2rem;
}

@media screen and (max-width: 900px) {
  #site-map-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #site-map-component {
    padding: 4rem 2rem;
  }

  #site-map-content {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    column-gap: 3rem;
    width: 100%;
    text-align: center;
  }

  #divider-line {
    transform: rotate(90deg);
    height: 16rem;
    margin: -4rem 0;
  }

  #address {
    min-width: 13rem;
  }

  #links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 2rem;
  }
}
</style>
