<template>
  <div id="home-view">
    <HeroComponent></HeroComponent>
    <GetInvolvedComponent></GetInvolvedComponent>
    <WhatsOnComponent></WhatsOnComponent>
    <ABitAboutUsComponent></ABitAboutUsComponent>
    <ChurchLifeComponent></ChurchLifeComponent>
    <BuildingHireComponent></BuildingHireComponent>
  </div>
</template>

<script>
import HeroComponent from "@/components/HomeComponents/HeroComponent.vue";
import GetInvolvedComponent from "@/components/HomeComponents/GetInvolvedComponent.vue";
import WhatsOnComponent from "@/components/HomeComponents/WhatsOnComponent.vue";
import ABitAboutUsComponent from "@/components/HomeComponents/ABitAboutUsComponent.vue";
import ChurchLifeComponent from "@/components/HomeComponents/ChurchLifeComponent.vue";
import BuildingHireComponent from "@/components/HomeComponents/BuildingHireComponent.vue";

export default {
  name: "HomeView",
  components: {
    HeroComponent,
    GetInvolvedComponent,
    WhatsOnComponent,
    ABitAboutUsComponent,
    ChurchLifeComponent,
    BuildingHireComponent,
  },
};
</script>

<style scoped></style>
