<template>
  <div id="hero-component">
    <picture id="hero-image">
      <!--Desktop Image-->
      <source
        media="(min-width: 601px)"
        srcset="@/assets/images/empty-church.webp"
      />
      <!--Mobile Image-->
      <source
        media="(max-width: 600px)"
        srcset="@/assets/images/empty-church-mobile.webp"
      />
      <source />
      <!--Backup Image-->
      <img
        src="@/assets/images/empty-church.png"
        alt="The Bridge Baptist Church decorated for a wedding"
      />
    </picture>
    <div id="hero-content">
      <h1>
        a small church, <br />
        a big <span>heart</span> for god
      </h1>
      <p>
        We’re a group of ordinary people who’ve found that following Jesus is
        the best way to live our lives
      </p>
      <RouterLink to="/about" class="primary-button">find out more</RouterLink>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroComponent",
};
</script>

<style scoped>
#hero-component {
  position: relative;
  width: 100%;
  height: calc(100dvh - 5rem);
  max-height: 45rem;
  /* background-color: var(--background-light); */
}

#hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

#hero-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

#hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  width: 100%;
  height: 100%;
  padding: 0 4rem;
  background-color: #00000099;
  backdrop-filter: blur(10px);
  text-align: center;
}

#hero-content h1 {
  color: var(--text-light);
}

#hero-content h1 span {
  color: var(--highlight);
}

#hero-content p {
  max-width: 40rem;
  font-size: 1.25rem;
  color: var(--text-light);
}

@media screen and (max-width: 600px) {
  #hero-content {
    row-gap: 1rem;
    padding: 2rem;
  }

  #hero-content p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1000px) and (max-height: 500px) {
  #hero-component {
    position: relative;
    width: 100%;
    height: calc(100dvh - 5rem);
    min-height: 18rem;
  }

  #hero-content {
    row-gap: 1rem;
    padding: 2rem;
  }

  #hero-content p {
    font-size: 1rem;
  }
}
</style>
