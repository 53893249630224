<template>
  <div id="about-view">
    <PageTitleComponent title="about"></PageTitleComponent>
    <WhoWeAreComponent></WhoWeAreComponent>
    <MinistryComponent></MinistryComponent>
    <MeetTheTeamComponent></MeetTheTeamComponent>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/AppComponents/PageTitleComponent.vue";
import WhoWeAreComponent from "@/components/AboutComponents/WhoWeAreComponent.vue";
import MinistryComponent from "@/components/AboutComponents/MinistryComponent.vue";
import MeetTheTeamComponent from "@/components/AboutComponents/MeetTheTeamComponent.vue";

export default {
  name: "AboutView",
  components: {
    PageTitleComponent,
    WhoWeAreComponent,
    MinistryComponent,
    MeetTheTeamComponent,
  },
};
</script>

<style scoped></style>
