<template>
  <div id="whats-on-component">
    <div id="whats-on-content">
      <h2>whats on</h2>
      <div id="carousel-track">
        <div class="event-card">
          <picture>
            <source srcset="@/assets/images/carousel/coffee.webp" />
            <img
              src="@/assets/images/carousel/coffee.png"
              alt=""
              loading="lazy"
              decoding="async"
            />
          </picture>
          <div class="event-card-info-container">
            <div class="event-card-header">
              <h3>connect</h3>
              <h5>Tuesday 1st April 10:00am</h5>
            </div>
            <p>Join us for our daytime small group in the upper room</p>
          </div>
        </div>
        <div class="event-card">
          <picture>
            <source srcset="@/assets/images/carousel/cafe.webp" />
            <img
              src="@/assets/images/carousel/cafe.png"
              alt=""
              loading="lazy"
              decoding="async"
            />
          </picture>
          <div class="event-card-info-container">
            <div class="event-card-header">
              <h3>cafe church</h3>
              <h5>Sunday 6th April 10:45am</h5>
            </div>
            <p>
              All welcome as we gather as a church family for coffee and cake in
              an informal setting to learn together from God’s word
            </p>
          </div>
        </div>
        <div class="divider">
          <span>this week</span>
          <div class="divider-line"></div>
          <span>coming soon</span>
        </div>
        <div class="event-card">
          <picture>
            <source srcset="@/assets/images/carousel/hands.webp" />
            <img
              src="@/assets/images/carousel/hands.png"
              alt=""
              loading="lazy"
              decoding="async"
            />
          </picture>
          <div class="event-card-info-container">
            <div class="event-card-header">
              <h3>small group</h3>
              <h5>Thursday 10th April 19:30pm</h5>
            </div>
            <p>
              Join us at Dave and Elaine's for freindship, study and worship
            </p>
          </div>
        </div>
        <div class="event-card">
          <picture>
            <source srcset="@/assets/images/carousel/cross.webp" />
            <img
              src="@/assets/images/carousel/cross.png"
              alt=""
              loading="lazy"
              decoding="async"
            />
          </picture>
          <div class="event-card-info-container">
            <div class="event-card-header">
              <h3>Palm Sunday</h3>
              <h5>Sunday 13th April 10:45am</h5>
            </div>
            <p>
              Join us on Palm Sunday for a shorter Morning service followed by a
              Church meeting. All welcome as we share news and information
              together.
            </p>
          </div>
        </div>
        <div class="event-card">
          <picture>
            <source srcset="@/assets/images/carousel/coffee.webp" />
            <img
              src="@/assets/images/carousel/coffee.png"
              alt=""
              loading="lazy"
              decoding="async"
            />
          </picture>
          <div class="event-card-info-container">
            <div class="event-card-header">
              <h3>connect</h3>
              <h5>Tuesday 15th April 10:00am</h5>
            </div>
            <p>Join us for our daytime small group in the upper room</p>
          </div>
        </div>
        <div class="event-card">
          <picture>
            <source srcset="@/assets/images/carousel/worship.webp" />
            <img
              src="@/assets/images/carousel/worship.png"
              alt=""
              loading="lazy"
              decoding="async"
            />
          </picture>
          <div class="event-card-info-container">
            <div class="event-card-header">
              <h3>Maundy Thursday Supper & Reflection</h3>
              <h5>Thursday 17th April 18:00pm</h5>
            </div>
            <p>
              Join us as we gather for a light supper. We will share communion
              and reflect and pray. (Please book your place with Elaine)
            </p>
          </div>
        </div>
        <div class="event-card">
          <picture>
            <source srcset="@/assets/images/carousel/cross.webp" />
            <img
              src="@/assets/images/carousel/cross.png"
              alt=""
              loading="lazy"
              decoding="async"
            />
          </picture>
          <div class="event-card-info-container">
            <div class="event-card-header">
              <h3>Easter Sunday</h3>
              <h5>Sunday 20th April 10:45am</h5>
            </div>
            <p>Join as we celebrate Jesus is Risen!</p>
          </div>
        </div>
      </div>
      <div id="helper-container">
        <button id="left-arrow">
          <img
            src="@/assets/utility/arrow.png"
            alt="left arrow"
            loading="lazy"
            decoding="async"
            draggable="false"
          />
        </button>
        <span>scroll</span>
        <button id="right-arrow">
          <img
            src="@/assets/utility/arrow.png"
            alt="right arrow"
            loading="lazy"
            decoding="async"
            draggable="false"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhatsOnComponent",
  data() {
    return {
      mouseDown: false,
      startX: null,
      scrollLeft: null,
      carouselTrack: null,
    };
  },
  mounted() {
    this.carouselTrack = document.querySelector("#carousel-track");

    this.carouselTrack.addEventListener("mousemove", this.move, false);
    this.carouselTrack.addEventListener("mousedown", this.startDragging, false);
    this.carouselTrack.addEventListener("mouseup", this.stopDragging, false);
    this.carouselTrack.addEventListener("mouseleave", this.stopDragging, false);

    document
      .querySelector("#left-arrow")
      .addEventListener("click", this.moveLeft, false);

    document
      .querySelector("#right-arrow")
      .addEventListener("click", this.moveRight, false);
  },
  beforeDestroyed() {
    this.carouselTrack.removeEventListener("mousemove", this.move, false);
    this.carouselTrack.removeEventListener(
      "mousedown",
      this.startDragging,
      false
    );
    this.carouselTrack.removeEventListener("mouseup", this.stopDragging, false);
    this.carouselTrack.removeEventListener(
      "mouseleave",
      this.stopDragging,
      false
    );

    document
      .querySelector("#left-arrow")
      .removeEventListener("click", this.moveLeft, false);

    document
      .querySelector("#right-arrow")
      .removeEventListener("click", this.moveRight, false);
  },
  methods: {
    startDragging(e) {
      this.mouseDown = true;
      this.startX = e.pageX - this.carouselTrack.offsetLeft;
      this.scrollLeft = this.carouselTrack.scrollLeft;
    },
    stopDragging() {
      this.mouseDown = false;
    },
    move(e) {
      e.preventDefault();

      if (!this.mouseDown) {
        return;
      }

      const x = e.pageX - this.carouselTrack.offsetLeft;
      const scroll = x - this.startX;
      this.carouselTrack.scrollLeft = this.scrollLeft - scroll;
    },
    moveLeft() {
      this.carouselTrack.style.scrollBehavior = "smooth";
      this.carouselTrack.scrollLeft = this.carouselTrack.scrollLeft - 307;
      this.carouselTrack.style.scrollBehavior = "unset";
    },
    moveRight() {
      this.carouselTrack.style.scrollBehavior = "smooth";
      this.carouselTrack.scrollLeft = this.carouselTrack.scrollLeft + 307;
      this.carouselTrack.style.scrollBehavior = "unset";
    },
  },
};
</script>

<style scoped>
#whats-on-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 0;
  background-color: var(--background-dark);
}

#whats-on-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;
  width: 100%;
}

h2,
h3,
h5,
p {
  color: var(--text-light);
}

#carousel-track {
  position: relative;
  display: flex;
  height: 400px;
  column-gap: 2rem;
  width: 100%;
  padding: 0 4rem;
  overflow: scroll;
  cursor: grab;
}

#carousel-track {
  scrollbar-width: none;
} /* Firefox */
#carousel-track {
  -ms-overflow-style: none;
} /* IE and Edge */
#carousel-track::-webkit-scrollbar,
#carousel-track::-webkit-scrollbar-button {
  display: none;
} /* Chrome */

.event-card {
  position: relative;
  width: 275px;
  min-width: 275px;
  height: 400px;
  border-radius: 2rem;
  overflow: hidden;
  z-index: 0;
}

.event-card picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.event-card picture img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-card-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  width: 100%;
  height: 100%;
  padding: 50% 1.5rem 1.5rem;
  text-align: center;
  background-color: #00000088;
  user-select: none;
}

.event-card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
}

.event-card-info-container h3 {
  height: fit-content;
  font-family: boldoa;
  font-size: 1.5rem;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
}

.event-card-info-container h5 {
  height: fit-content;
  font-family: bree-serif;
  font-size: 0.8rem;
  line-height: 1;
  text-align: center;
}

.event-card-info-container p {
  margin-bottom: 0.5rem;
}

.divider {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.divider span {
  height: fit-content;
  color: var(--text-light);
  font-family: boldoa;
  font-size: 2rem;
  text-transform: uppercase;
  line-height: 1.4;
  text-align: center;
  text-wrap: nowrap;
  user-select: none;
}

.divider span:first-child {
  writing-mode: vertical-lr;
}

.divider span:last-child {
  writing-mode: vertical-lr;
  rotate: 180deg;
}

.divider-line {
  height: 100%;
  width: 0.5rem;
  padding: 2rem 0;
  background-color: var(--highlight);
  border-radius: 10rem;
}

#helper-container {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

#helper-container button {
  background-color: unset;
  border: none;
  cursor: pointer;
}

#helper-container img {
  height: 1.5rem;
}

#right-arrow img:first-child {
  transform: scaleX(-1);
}

#helper-container span {
  color: var(--text-light);
  font-family: boldoa;
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
}
</style>
