<template>
  <div id="safeguarding-view">
    <PageTitleComponent title="safeguarding"></PageTitleComponent>
    <WhatIsSafeguardingComponent></WhatIsSafeguardingComponent>
    <OurPromiseComponent></OurPromiseComponent>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/AppComponents/PageTitleComponent.vue";
import WhatIsSafeguardingComponent from "@/components/SafeguardingComponents/WhatIsSafeguardingComponent.vue";
import OurPromiseComponent from "@/components/SafeguardingComponents/OurPromiseComponent.vue";

export default {
  name: "SafeguardingView",
  components: {
    PageTitleComponent,
    WhatIsSafeguardingComponent,
    OurPromiseComponent,
  },
};
</script>

<style scoped></style>
