<template>
  <div id="contact-form-component">
    <ToastNotification
      :info="toastInfo"
      v-if="toastVisible"
      @close-toast="closeToast"
    ></ToastNotification>
    <form
      id="contact-form-content"
      name="contact"
      data-netlify="true"
      @submit.prevent="handleSubmit"
    >
      <h2>get in touch</h2>
      <div id="inputs-container">
        <input type="hidden" name="form-name" value="contact" />
        <div id="personal-details-inputs-container">
          <label for="name-input">Name:</label>
          <input
            v-model="form.name"
            id="name-input"
            placeholder="Your name ..."
            type="text"
            name="name"
            autocomplete="name"
          />
          <label for="email-input">Email:</label>
          <input
            v-model="form.email"
            id="email-input"
            placeholder="Your email ..."
            type="text"
            name="email"
            autocomplete="email"
          />
        </div>
        <div id="message-input-container">
          <label for="message-input">Message:</label>
          <textarea
            v-model="form.message"
            id="message-input"
            placeholder="Your message ..."
            rows="4"
            name="message"
          ></textarea>
        </div>
      </div>
      <button type="submit" id="submit-button" class="primary-button">
        Submit
      </button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import ToastNotification from "./ToastNotification.vue";

export default {
  name: "ContactFormComponent",
  components: { ToastNotification },
  data() {
    return {
      form: {
        name: "",
        email: "",
        message: "",
      },

      toastInfo: {
        title: "Success",
        body: "Your message was sent successfully",
      },

      toastVisible: false,

      formSending: false,
    };
  },
  methods: {
    closeToast() {
      this.toastVisible = false;
    },
    handleSubmit() {
      let emailValid = this.validateEmail();

      if (emailValid) {
        this.postFormContent();
      } else {
        this.toastInfo.title = "Invalid Email";
        this.toastInfo.body =
          "It looks like the email you've entered is invalid. Please check you've entered it correctly.";

        this.toastVisible = true;

        setTimeout(() => {
          this.toastVisible = false;
        }, 5000);
      }
    },
    validateEmail() {
      const emailInput = document.querySelector("#email-input");

      var validRegex =
        //eslint-disable-next-line
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (
        this.form.email === "" ||
        !this.form.email.toLocaleLowerCase().match(validRegex)
      ) {
        emailInput.classList.add("invalid-input");
        return false;
      } else {
        emailInput.classList.remove("invalid-input");
        return true;
      }
    },
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    postFormContent() {
      this.formSending = true;

      const axiosConfig = {
        header: { "Content-Type": "application/x-www-form-urlencoded" },
      };

      axios
        .post(
          "/",
          this.encode({
            "form-name": "contact",
            ...this.form,
          }),
          axiosConfig
        )
        .then((response) => {
          this.handleResponse(response);
        })
        .catch((e) => {
          this.handleResponse(e.response);
        });
    },
    handleResponse(response) {
      if (response.status === 200) {
        this.toastInfo.title = "Sent";
        this.toastInfo.body = "Your message sent successfully.";

        this.form.name = "";
        this.form.email = "";
        this.form.message = "";
      } else {
        this.toastInfo.title = "Oops";
        this.toastInfo.body = "There was an issue sending your message.";
      }

      this.toastVisible = true;

      setTimeout(() => {
        this.toastVisible = false;
      }, 5000);

      this.formSending = false;
    },
  },
};
</script>

<style scoped>
#contact-form-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--grey-3);
}

#contact-form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;
  width: 100%;
  max-width: 90rem;
}

#inputs-container {
  display: flex;
  justify-content: center;
  column-gap: 4rem;
  row-gap: 2rem;
  flex-wrap: wrap;
}

#personal-details-inputs-container {
  display: flex;
  flex-direction: column;
}

#message-input-container {
  display: flex;
  flex-direction: column;
}

#message-input {
  flex-grow: 1;
}

#name-input {
  margin-bottom: 2rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text-light);
}

input,
textarea {
  width: 20rem;
  padding: 1rem;
  border: 2px solid transparent;
  border-radius: 1rem;
  font-size: 1rem;
}

textarea {
  resize: none;
}

input::placeholder,
textarea::placeholder {
  color: #00000066;
}

input:focus,
textarea:focus {
  outline: 4px solid var(--highlight);
}

h2 {
  color: var(--text-light);
}

@media screen and (max-width: 900px) {
  #contact-form-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #contact-form-component {
    padding: 4rem 2rem;
  }
}

@media screen and (max-width: 600px) {
  input,
  textarea {
    width: 100%;
  }

  #personal-details-inputs-container {
    width: 100%;
  }

  #message-input-container {
    width: 100%;
  }
}
</style>
