<template>
  <div id="gallery-component">
    <div id="gallery-content"></div>
    <div id="modal-container" class="visibility-hidden" @click="closeModal">
      <div id="modal">
        <button id="modal-close-button" title="Close modal">
          <img
            src="@/assets/utility/close-icon.svg"
            alt="Close modal"
            @click="closeModal"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GalleryComponent",
  data() {
    return {
      noOfColumns: 4,
      images: [
        {
          title: "It doesn't get any better than this!",
          webp: require("@/assets/images/gallery/baptism-1.webp"),
          png: require("@/assets/images/gallery/baptism-1.png"),
        },
        {
          title: "The 2023 Christmas service",
          webp: require("@/assets/images/gallery/christmas-service-2023.webp"),
          png: require("@/assets/images/gallery/christmas-service-2023.png"),
        },
        {
          title: "The 2023 Christmas social",
          webp: require("@/assets/images/gallery/christmas-social-2023.webp"),
          png: require("@/assets/images/gallery/christmas-social-2023.png"),
        },
        {
          title: "This is what I was born for!",
          webp: require("@/assets/images/gallery/baptism-2.webp"),
          png: require("@/assets/images/gallery/baptism-2.png"),
        },
        {
          title: "Our church building",
          webp: require("@/assets/images/gallery/church-front.webp"),
          png: require("@/assets/images/gallery/church-front.png"),
        },
        {
          title:
            "Our church setup for the 2023 Christmas service bababab bababab bababab babababa bababba",
          webp: require("@/assets/images/gallery/church-setup-1.webp"),
          png: require("@/assets/images/gallery/church-setup-1.png"),
        },
        {
          title: "Our church setup for a wedding",
          webp: require("@/assets/images/gallery/church-setup-2.webp"),
          png: require("@/assets/images/gallery/church-setup-2.png"),
        },
        {
          title: "Our church building",
          webp: require("@/assets/images/gallery/church-sign.webp"),
          png: require("@/assets/images/gallery/church-sign.png"),
        },
        {
          title: "The Jubilee church social",
          webp: require("@/assets/images/gallery/jubilee-social.webp"),
          png: require("@/assets/images/gallery/jubilee-social.png"),
        },
        {
          title: "The technical team with beaming smiles",
          webp: require("@/assets/images/gallery/pa-desk.webp"),
          png: require("@/assets/images/gallery/pa-desk.png"),
        },
        {
          title: "A performance at our church by the local primary school",
          webp: require("@/assets/images/gallery/school-performance-2023.webp"),
          png: require("@/assets/images/gallery/school-performance-2023.png"),
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("resize", this.windowSizeChanged);
    this.windowSizeChanged();
    this.setupMasonry();
  },
  beforeDestroyed() {
    window.removeEventListener("resize", this.windowSizeChanged);
  },
  methods: {
    windowSizeChanged() {
      const rem = parseFloat(
        getComputedStyle(document.documentElement).fontSize
      );
      const columnWidth = 16 * rem;
      const columnGap = 2 * rem;
      const padding = 4 * rem;

      let currentNoOfColumns = this.noOfColumns;

      if (window.innerWidth > columnWidth * 4 + columnGap * 3 + padding) {
        this.noOfColumns = 4;
      } else if (
        window.innerWidth >
        columnWidth * 3 + columnGap * 2 + padding
      ) {
        this.noOfColumns = 3;
      } else if (
        window.innerWidth >
        columnWidth * 2 + columnGap * 1 + padding
      ) {
        this.noOfColumns = 2;
      } else {
        this.noOfColumns = 1;
      }

      if (this.noOfColumns != currentNoOfColumns) {
        this.setupMasonry();
      }
    },
    setupMasonry() {
      const galleryContent = document.querySelector("#gallery-content");

      galleryContent.innerHTML = "";

      const columns = [];

      for (let x = 0; x < this.noOfColumns; x++) {
        const column = document.createElement("div");
        column.setAttribute("class", "masonry-column");
        columns.push(column);
      }

      columns.forEach((column) => {
        galleryContent.appendChild(column);
      });

      this.fillColumns(columns);
    },
    fillColumns(columns) {
      //Distribute images amongst the columns evenly
      const remainder = this.images.length % columns.length;

      let colNo = 0;

      for (let i = 0; i < this.images.length - remainder; i++) {
        const picture = this.getPictureElement(i);

        columns[colNo].appendChild(picture);

        if (colNo < columns.length - 1) {
          colNo++;
        } else {
          colNo = 0;
        }
      }

      //If the division results in a remainder, distribute the remaining images evenly

      colNo = 0;

      for (
        let j = this.images.length - remainder;
        j < this.images.length;
        j++
      ) {
        const picture = this.getPictureElement(j);

        columns[colNo].appendChild(picture);

        if (colNo < columns.length - 1) {
          colNo++;
        } else {
          colNo = 0;
        }
      }
    },
    getPictureElement(index) {
      const picture = document.createElement("picture");
      const source = document.createElement("source");
      const image = document.createElement("img");

      source.srcset = this.images[index].webp;
      image.src = this.images[index].png;
      image.setAttribute("alt", this.images[index].title);
      picture.setAttribute("title", this.images[index].title);

      picture.appendChild(source);
      picture.appendChild(image);

      picture.addEventListener("click", (e) => {
        this.showModal(e);
      });

      return picture;
    },
    showModal(e) {
      const modalContainer = document.querySelector("#modal-container");
      const modal = document.querySelector("#modal");

      const picture = e.target.cloneNode(true);
      const title = document.createElement("h3");
      title.innerHTML = e.target.title;

      while (modal.childNodes.length > 1) {
        modal.removeChild(modal.lastChild);
      }

      modal.appendChild(picture);
      modal.appendChild(title);

      modalContainer.classList.remove("visibility-hidden");
    },
    closeModal(e) {
      const modalContainer = document.querySelector("#modal-container");
      const modalCloseButton = document.querySelector("#modal-close-button");

      if (e.target === modalContainer || e.target === modalCloseButton) {
        modalContainer.classList.add("visibility-hidden");
      }
    },
  },
};
</script>

<style>
#gallery-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 2rem;
  background-color: var(--background-light);
}

#gallery-content {
  display: flex;
  justify-content: center;
  column-gap: 2rem;
  row-gap: 2rem;
  width: 100%;
  max-width: 90rem;
}

#gallery-content .masonry-column {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  width: 16rem;
  min-width: 16rem;
  height: fit-content;
}

#gallery-content .masonry-column picture {
  width: 100%;
  cursor: pointer;
}

#gallery-content .masonry-column picture img {
  width: 100%;
  border-radius: 1.25rem;
  pointer-events: none;
}

.visibility-hidden {
  visibility: hidden;
}

#modal-container {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  background-color: #00000099;
  z-index: 99;
  cursor: pointer;
}

#modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;
  width: fit-content;
  max-height: 100%;
  padding: 4rem;
  background-color: var(--background-light);
  border-radius: 2rem;
  text-align: center;
  cursor: default;
  overflow: scroll;
}

#modal picture {
  height: 30rem;
}

#modal picture img {
  height: 100%;
}

#modal-close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem;
}

#modal-close-button img {
  width: 2rem;
  height: 2rem;
  pointer-events: none;
}

@media screen and (max-width: 900px) {
  #modal picture {
    max-width: 30rem;
    height: unset;
  }

  #modal picture img {
    width: 100%;
    height: unset;
  }
}

@media screen and (max-width: 750px) {
  #modal-container {
    padding: 1rem;
  }
}

@media screen and (max-width: 450px) {
  #modal-container {
    padding: 0.5rem;
  }

  #modal {
    padding: 4rem 1rem;
  }
}

@media screen and (max-width: 400px) {
  #gallery-component {
    padding: 4rem 1rem;
  }
}
</style>
