<template>
  <div id="ministry-component">
    <div id="ministry-content">
      <picture>
        <source srcset="@/assets/images/frames/worship.webp" />
        <img
          src="@/assets/images/frames/worship.png"
          alt=""
          loading="lazy"
          decoding="async"
        />
      </picture>
      <div id="info-container">
        <h2>ministry</h2>
        <p>
          We meet regularly on Sunday Mornings at 10.45am for lively
          contemporary worship and we also meet during the week for Bible Study,
          or to follow a short teaching series.
          <br />
          <br />
          Prayer is central to our life together. We believe that pray is simply
          about paying attention to God who is present – we can pray at anytime,
          anywhere. We do gather each month for a prayer event when we take time
          together to pray and share, often using prayer stations to help us
          pray in helpful and creative ways.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MinistryComponent",
};
</script>

<style scoped>
#ministry-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--grey-1);
}

#ministry-content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 4rem;
  width: 100%;
  max-width: 90rem;
}

#ministry-content picture {
  position: relative;
  width: 40%;
  max-width: 32rem;
}

#ministry-content picture img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
  width: 60%;
}

h2 {
  text-align: left;
}

@media screen and (max-width: 900px) {
  #ministry-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #ministry-component {
    padding: 4rem 2rem;
  }

  #ministry-content {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    row-gap: 2rem;
  }

  #ministry-content picture {
    width: 100%;
    max-width: 24rem;
  }

  #info-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  h2 {
    text-align: center;
  }
}
</style>
