<template>
  <div id="toast-notification-component">
    <div class="header">
      <span class="title">{{ info.title }}</span>
      <button id="close-button" title="Close toast notification">
        <img
          src="@/assets/utility/close-icon.svg"
          alt="Close toast notification"
          @click="closeToast"
          loading="lazy"
          decoding="async"
        />
      </button>
    </div>
    <span class="body">{{ info.body }}</span>
  </div>
</template>

<script>
export default {
  name: "ToastNotification",
  props: {
    info: Object,
  },
  methods: {
    closeToast() {
      this.$emit("close-toast");
    },
  },
};
</script>

<style scoped>
#toast-notification-component {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  width: 18rem;
  height: 12rem;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 10px 20px #00000077;
  overflow: hidden;
  z-index: 999;
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 1rem;
  background-color: var(--highlight);
}

#close-button {
  position: absolute;
  top: 50%;
  right: 0;
  padding: 1rem;
  transform: translateY(-50%);
}

#close-button img {
  width: 1rem;
  height: 1rem;
  filter: invert(1);
}

.title {
  color: var(--text-light);
  font-size: 1.25rem;
}

.body {
  width: 100%;
  padding: 1rem 1rem;
}

@media screen and (max-width: 400px) {
  #toast-notification-component {
    right: 50%;
    width: 16rem;
    transform: translateX(50%);
  }
}
</style>
