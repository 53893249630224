<template>
  <div id="service-types-component">
    <div id="service-types-content">
      <picture>
        <source srcset="@/assets/images/frames/bridge-front.webp" />
        <img src="@/assets/images/frames/bridge-front.png" alt="" />
      </picture>
      <div id="info-container">
        <h2>service types</h2>
        <p>
          On the first Sunday of the month we have a cafe style church where we
          sit round tables and have drinks and breakfast pastries. This way of
          worshipping together is a little more interactive and may involve a
          table activity. It’s not just the children who enjoy this style but
          everyone!
          <br /><br />
          On the second Sunday of the month we also share Communion together –
          eating bread and drinking wine, focusing our thoughts on all that
          Jesus has done for us. This time is very special. At the Communion
          table we expect to meet Jesus afresh and invite Him to continue to
          work in our lives.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceTypesComponent",
};
</script>

<style scoped>
#service-types-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--background-light);
}

#service-types-content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 4rem;
  width: 100%;
  max-width: 90rem;
}

#service-types-content picture {
  position: relative;
  width: 40%;
  max-width: 32rem;
}

#service-types-content picture img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
  width: 60%;
}

h2 {
  text-align: left;
}

@media screen and (max-width: 900px) {
  #service-types-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #service-types-component {
    padding: 4rem 2rem;
  }

  #service-types-content {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    row-gap: 2rem;
  }

  #service-types-content picture {
    width: 100%;
    max-width: 24rem;
  }

  #info-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  h2 {
    text-align: center;
  }
}
</style>
