<template>
  <div id="coming-together-component">
    <div id="coming-together-content">
      <picture>
        <source srcset="@/assets/images/frames/get-together-2025.webp" />
        <img src="@/assets/images/frames/get-together-2025.png" alt="" />
      </picture>
      <div id="info-container">
        <h2>coming together</h2>
        <p>
          As a church community, we enjoy meeting for social events throughout
          the year. These events give us the opportunity just to ‘be together’.
          Check out a couple of our most recent events below.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MosaicComponent",
};
</script>

<style scoped>
#coming-together-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--background-light);
}

#coming-together-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 4rem;
  width: 100%;
  max-width: 90rem;
}

#coming-together-content picture {
  position: relative;
  width: 40%;
  max-width: 32rem;
}

#coming-together-content picture img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
  width: 60%;
}

h2 {
  text-align: left;
}

@media screen and (max-width: 900px) {
  #coming-together-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #coming-together-component {
    padding: 4rem 2rem;
  }

  #coming-together-content {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    row-gap: 2rem;
  }

  #coming-together-content picture {
    width: 100%;
    max-width: 24rem;
  }

  #info-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  h2 {
    text-align: center;
  }
}
</style>
