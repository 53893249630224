<template>
  <div id="when-are-we-meeting-component">
    <div id="when-are-we-meeting-content">
      <h2>when are we meeting?</h2>
      <div id="calendar-container">
        <div class="month-container">
          <span>apr</span>
          <div class="dates-container">
            <div class="date">
              <img src="@/assets/graphics/circle-red.png" alt="" />
              <span>1</span>
            </div>
            <div class="date">
              <img src="@/assets/graphics/circle-grey-2.png" alt="" />
              <span>15</span>
            </div>
            <div class="date">
              <img src="@/assets/graphics/circle-grey-2.png" alt="" />
              <span>29</span>
            </div>
          </div>
        </div>
        <div class="month-container">
          <span>may</span>
          <div class="dates-container">
            <div class="date">
              <img src="@/assets/graphics/circle-grey-2.png" alt="" />
              <span>13</span>
            </div>
            <div class="date">
              <img src="@/assets/graphics/circle-grey-2.png" alt="" />
              <span>27</span>
            </div>
          </div>
        </div>
      </div>
      <span>10am @ the bridge</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhenAreWeMeetingComponent",
};
</script>

<style scoped>
#when-are-we-meeting-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--grey-1);
}

#when-are-we-meeting-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;
  width: 100%;
  max-width: 90rem;
}

#calendar-container {
  display: flex;
  justify-content: center;
  column-gap: 8rem;
  row-gap: 4rem;
  flex-wrap: wrap;
  width: 100%;
}

.month-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
}

#when-are-we-meeting-content span {
  font-family: boldoa;
  font-size: 2.5rem;
  text-transform: uppercase;
  line-height: 1.4;
  text-align: center;
}

.dates-container {
  display: flex;
  justify-content: center;
  column-gap: 2rem;
  row-gap: 2rem;
  flex-wrap: wrap;
}

.date {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
}

.date img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.date span {
  color: var(--text-light);
  font-size: 3rem;
  z-index: 1;
  text-transform: uppercase;
}

@media screen and (max-width: 1250px) {
  #calendar-container-container {
    column-gap: 4rem;
  }
}

@media screen and (max-width: 900px) {
  #when-are-we-meeting-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #when-are-we-meeting-component {
    padding: 4rem 2rem;
  }
}
</style>
