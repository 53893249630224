<template>
  <header>
    <RouterLink id="home-button" to="/" title="Go to The Bridge home">
      <img id="logo" src="@/assets/logos/logo-red.png" alt="The Bridge home" />
    </RouterLink>
    <nav id="main-navigation" class="mobile-visiblity-hidden">
      <RouterLink to="/about">About</RouterLink>
      <RouterLink to="/service">Service</RouterLink>
      <RouterLink to="/children">Children</RouterLink>
      <RouterLink to="/midweek">Midweek</RouterLink>
      <RouterLink to="/social">Social</RouterLink>
      <RouterLink to="/community">Community</RouterLink>
      <div id="more-button" @click="handleSubNavigation">
        More
        <div id="sub-navigation">
          <button
            id="back-button"
            @click="closeSubNavigation"
            title="Go back to the main menu"
          >
            <img src="@/assets/utility/arrow.png" alt="Back arrow" /><span
              >Back</span
            >
          </button>
          <RouterLink to="/safeguarding">Safeguarding</RouterLink>
          <RouterLink to="/membership">Membership</RouterLink>
          <RouterLink to="/gallery">Gallery</RouterLink>
        </div>
      </div>

      <button class="primary-button" @click="scrollToContactForm">
        Contact
      </button>
    </nav>
    <button id="menu-toggle" @click="handleMobileNavigation">
      <img
        v-if="!mobileMenuOpen"
        src="@/assets/utility/menu-icon.svg"
        alt="Menu toggle open"
      />
      <img
        v-if="mobileMenuOpen"
        src="@/assets/utility/close-icon.svg"
        alt="Menu toggle close"
      />
    </button>
  </header>
</template>

<script>
export default {
  name: "HeaderComponent",
  data() {
    return {
      mobileMenuOpen: false,
      subNavigationOpen: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.windowSizeChanged);
    window.addEventListener("scroll", this.windowScroll);

    document
      .querySelector("#router-view-container")
      .addEventListener("click", () => {
        this.closeNavigation();
      });

    const mainNavigation = document.querySelector("#main-navigation");

    mainNavigation.addEventListener("click", (e) => {
      if (
        e.clientX > e.target.offsetWidth &&
        e.clientY > e.target.offsetTop &&
        mainNavigation === e.target
      ) {
        this.closeNavigation();
      }
    });
  },
  beforeDestroyed() {
    window.removeEventListener("resize", this.windowSizeChanged);
  },
  watch: {
    $route() {
      this.closeNavigation();
    },
  },
  methods: {
    windowScroll() {
      const header = document.querySelector("header");
      if (window.scrollY > 100) {
        header.setAttribute("scrolled", "");
      } else {
        header.removeAttribute("scrolled");
      }
    },
    handleMobileNavigation() {
      if (this.mobileMenuOpen) {
        this.closeNavigation();
      } else {
        this.openMobileNavigation();
      }
    },
    openMobileNavigation() {
      const nav = document.querySelector("#main-navigation");

      nav.classList.remove("mobile-visiblity-hidden");

      this.mobileMenuOpen = true;
    },
    closeNavigation() {
      const nav = document.querySelector("#main-navigation");

      nav.classList.add("mobile-visiblity-hidden");

      this.mobileMenuOpen = false;

      this.closeSubNavigation();
    },
    windowSizeChanged(e) {
      if (e.target.innerWidth > 900) {
        const nav = document.querySelector("#main-navigation");
        nav.classList.add("mobile-visiblity-hidden");
        this.mobileMenuOpen = false;
      }
    },
    scrollToContactForm() {
      const contactForm = document.querySelector("#contact-form-component");
      const headerBar = document.querySelector("header");

      const topOfElement = contactForm.offsetTop - headerBar.offsetHeight;
      window.scroll({ top: topOfElement, behavior: "smooth" });
      this.closeNavigation();
    },
    handleSubNavigation(e) {
      const moreButton = document.querySelector("#more-button");
      const subNavigation = document.querySelector("#sub-navigation");

      if (e.target === moreButton) {
        if (this.subNavigationOpen) {
          subNavigation.style.removeProperty("display");
          moreButton.classList.remove("more-button-active");
        } else {
          subNavigation.style.display = "flex";
          moreButton.classList.add("more-button-active");
        }
        this.subNavigationOpen = !this.subNavigationOpen;
      }
    },
    closeSubNavigation() {
      const moreButton = document.querySelector("#more-button");
      const subNavigation = document.querySelector("#sub-navigation");

      subNavigation.style.removeProperty("display");
      moreButton.classList.remove("more-button-active");

      this.subNavigationOpen = false;
    },
  },
};
</script>

<style scoped>
header {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 6rem;
  padding: 0 1rem;
  background-color: var(--background-light);
  border-bottom: 2px solid var(--grey-1);
  z-index: 2;
  transition: height 0.2s ease;
}

header[scrolled] {
  height: 5rem;
}

#menu-toggle {
  display: none;
}

#home-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 1.5rem);
  padding: 0.25rem;
  aspect-ratio: 1 / 1;
  border: 0.25rem solid var(--highlight);
  border-radius: 50%;
  background-color: var(--highlight);
  transition: all 0.2s ease;
}

#logo {
  width: 100%;
  filter: brightness(0) invert();
  transition: all 0.2s ease;
}

#main-navigation {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.primary-button {
  text-align: left;
}

#main-navigation a {
  padding: 1rem;
  font-family: boldoa;
  text-transform: uppercase;
  border-radius: 1rem;
}

#more-button {
  position: relative;
  padding: 1rem;
  font-family: boldoa;
  text-transform: uppercase;
  border-radius: 1rem 1rem 0 0;
  line-height: 1.4;
  cursor: pointer;
}

.more-button-active {
  background-color: var(--grey-1);
}

#back-button {
  display: none;
}

#back-button img {
  filter: invert();
}

#sub-navigation {
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
  flex-direction: column;
  padding: 0.5rem;
  background-color: var(--grey-1);
  z-index: 1;
  border-radius: 1rem 0 1rem 1rem;
}

@media (hover: hover) {
  header[scrolled]:hover {
    height: 6rem;
  }

  #home-button:hover {
    background-color: unset;
  }

  #home-button:hover #logo {
    filter: none;
  }

  #main-navigation a:hover {
    text-decoration: underline dashed var(--highlight);
    text-decoration-thickness: 0.25rem;
    text-underline-offset: 0.5rem;
  }

  #more-button:hover {
    background-color: var(--grey-1);
  }

  #more-button:hover #sub-navigation,
  #sub-navigation:hover {
    display: flex;
  }
}

@media screen and (max-width: 900px) {
  #main-navigation {
    position: fixed;
    top: 6rem;
    bottom: 0;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 0;
    background-color: var(--grey-1);
  }

  header[scrolled] #main-navigation {
    top: 5rem;
  }

  #main-navigation:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    right: -250rem;
    background-color: #00000099;
  }

  #menu-toggle {
    display: block;
    padding: 0.5rem;
  }

  #menu-toggle img {
    width: 2rem;
    height: 2rem;
  }

  .mobile-visiblity-hidden {
    visibility: hidden;
  }

  #main-navigation a {
    width: 100%;
    padding: 1rem 2rem;
  }

  #main-navigation .primary-button {
    width: 100%;
    padding: 1rem 2rem;
    background-color: unset;
    color: var(--text-dark);
  }

  #more-button {
    width: 100%;
    padding: 1rem 2rem;
    border-radius: 0;
  }

  .more-button-active {
    background-color: var(--grey-2);
  }

  #sub-navigation {
    top: 0;
    right: unset;
    left: 100%;
    padding: 0.5rem;
    background-color: var(--grey-2);
    border-radius: 0 1rem 1rem 0;
  }
}

@media (hover: hover) and (max-width: 900px) {
  #more-button:hover {
    background-color: var(--grey-2);
  }

  #main-navigation .primary-button:hover {
    text-decoration: underline dashed var(--highlight);
    text-decoration-thickness: 0.25rem;
    text-underline-offset: 0.5rem;
  }
}

@media screen and (max-width: 500px) {
  #main-navigation {
    right: 0;
  }

  #main-navigation:before {
    display: none;
  }

  #main-navigation a {
    width: 12rem;
    padding: 1rem 2rem;
  }

  #main-navigation .primary-button {
    width: 12rem;
    background-color: unset;
  }

  #more-button {
    width: 12rem;
    border-radius: 0 1rem 1rem 0;
  }

  #sub-navigation {
    position: fixed;
    top: 6rem;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2rem 0;
    background-color: var(--grey-2);
    border-radius: 0;
  }

  header[scrolled] #sub-navigation {
    top: 5rem;
  }

  #back-button {
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
    width: 12rem;
    padding: 1rem 2rem;
    border-radius: 0 1rem 1rem 0;
    font-family: boldoa;
    text-align: left;
    text-transform: uppercase;
  }

  #back-button img {
    height: 1rem;
  }
}

@media (hover: hover) and (max-width: 500px) {
  #more-button:hover #sub-navigation,
  #sub-navigation:hover {
    display: none;
  }

  #back-button:hover {
    background-color: var(--grey-1);
  }
}

@media screen and (max-width: 1000px) and (max-height: 500px) {
  #main-navigation {
    right: 0;
    align-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }

  #main-navigation:before {
    display: none;
  }

  #main-navigation a {
    width: 12rem;
    padding: 1rem 2rem;
  }

  #main-navigation .primary-button {
    width: 12rem;
    background-color: unset;
  }

  #more-button {
    width: 12rem;
    border-radius: 0 1rem 1rem 0;
  }

  #sub-navigation {
    position: fixed;
    top: 6rem;
    bottom: 0;
    left: 0;
    right: 0;
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2rem 0;
    background-color: var(--grey-2);
    border-radius: 0;
  }

  header[scrolled] #sub-navigation {
    top: 5rem;
  }

  #back-button {
    display: flex;
    column-gap: 0.75rem;
    width: 12rem;
    padding: 1rem 2rem;
    border-radius: 0 1rem 1rem 0;
    font-family: boldoa;
    text-align: left;
    text-transform: uppercase;
  }

  #back-button img {
    height: 1rem;
  }
}

@media (hover: hover) and (max-width: 1000px) and (max-height: 500px) {
  #more-button:hover #sub-navigation,
  #sub-navigation:hover {
    display: none;
  }

  #back-button:hover {
    background-color: var(--grey-1);
  }
}
</style>
