<template>
  <div id="our-service-component">
    <div id="our-service-content">
      <picture>
        <source srcset="@/assets/images/frames/worship.webp" />
        <img src="@/assets/images/frames/worship.png" alt="" />
      </picture>
      <div id="info-container">
        <h2>our service</h2>
        <p>
          During the service we worship God through singing, prayer and
          listening to a talk which is generally around 20- 25 minutes or so.
          <br /><br />
          As we gather we acknowledge that God is present with us, we try to
          lean in to Him to listen to His Holy Spirit so that we can be
          encouraged, guided and challenged. The atmosphere in church is quite
          relaxed, our songs are contemporary and everyone is welcome to just be
          themselves.
          <br /><br />
          After the service, we usually have a drink and a biscuit and catch up
          with each other’s news.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurServiceComponent",
};
</script>

<style scoped>
#our-service-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--grey-1);
}

#our-service-content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 4rem;
  width: 100%;
  max-width: 90rem;
}

#our-service-content picture {
  position: relative;
  width: 40%;
  max-width: 32rem;
}

#our-service-content picture img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
  width: 60%;
}

h2 {
  text-align: left;
}

@media screen and (max-width: 900px) {
  #our-service-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #our-service-component {
    padding: 4rem 2rem;
  }

  #our-service-content {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    row-gap: 2rem;
  }

  #our-service-content picture {
    width: 100%;
    max-width: 24rem;
  }

  #info-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  h2 {
    text-align: center;
  }
}
</style>
