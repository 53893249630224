<template>
  <div id="gallery-view">
    <PageTitleComponent title="gallery"></PageTitleComponent>
    <GalleryComponent></GalleryComponent>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/AppComponents/PageTitleComponent.vue";
import GalleryComponent from "@/components/GalleryComponents/GalleryComponent.vue";

export default {
  name: "GalleryView",
  components: {
    PageTitleComponent,
    GalleryComponent,
  },
};
</script>

<style scoped></style>
