<template>
  <div id="service-view">
    <PageTitleComponent title="service"></PageTitleComponent>
    <WhenAndWhereComponent></WhenAndWhereComponent>
    <OurServiceComponent></OurServiceComponent>
    <AccessibilityComponent></AccessibilityComponent>
    <ServiceTypesComponent></ServiceTypesComponent>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/AppComponents/PageTitleComponent.vue";
import WhenAndWhereComponent from "@/components/ServiceComponents/WhenAndWhereComponent.vue";
import OurServiceComponent from "@/components/ServiceComponents/OurServiceComponent.vue";
import AccessibilityComponent from "@/components/ServiceComponents/AccessibilityComponent.vue";
import ServiceTypesComponent from "@/components/ServiceComponents/ServiceTypesComponent.vue";

export default {
  name: "ServiceView",
  components: {
    PageTitleComponent,
    WhenAndWhereComponent,
    OurServiceComponent,
    AccessibilityComponent,
    ServiceTypesComponent,
  },
};
</script>

<style scoped></style>
