<template>
  <div id="who-we-are-component">
    <div id="who-we-are-content">
      <picture>
        <source srcset="@/assets/images/frames/get-together-2025.webp" />
        <img
          src="@/assets/images/frames/get-together-2025.png"
          alt=""
          loading="lazy"
          decoding="async"
        />
      </picture>
      <div id="info-container">
        <h2>who we are</h2>
        <p>
          We're a group of ordinary people who have found that following Jesus
          is the best way to live our lives. Daily we want to walk with Jesus
          and work with Him so that we can enjoy our lives, learning how to live
          freely and lightly as He leads us and works in and through us.
          <br />
          <br />
          We are all at different stages on our journey in life with Jesus –
          some just starting out, others who have been disciples of Jesus for
          many many years.
          <br />
          <br />
          Our Church includes children, families, those who live alone but who
          are part of our Church family, we come from different walks of life
          and from different cultural backgrounds. Recently we have been
          delighted to welcome some people from Hongkong(Chinese: 香港人).
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhoWeAreComponent",
};
</script>

<style scoped>
#who-we-are-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--background-light);
}

#who-we-are-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 4rem;
  width: 100%;
  max-width: 90rem;
}

#who-we-are-content picture {
  position: relative;
  width: 40%;
  max-width: 32rem;
}

#who-we-are-content picture img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
  width: 60%;
}

h2 {
  text-align: left;
}

@media screen and (max-width: 900px) {
  #who-we-are-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #who-we-are-component {
    padding: 4rem 2rem;
  }

  #who-we-are-content {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    row-gap: 2rem;
  }

  #who-we-are-content picture {
    width: 100%;
    max-width: 24rem;
  }

  #info-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  h2 {
    text-align: center;
  }
}
</style>
