<template>
  <div id="midweek-view">
    <PageTitleComponent title="midweek"></PageTitleComponent>
    <ConnectComponent></ConnectComponent>
    <WhenAreWeMeetingComponent></WhenAreWeMeetingComponent>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/AppComponents/PageTitleComponent.vue";
import ConnectComponent from "@/components/MidweekComponents/ConnectComponent.vue";
import WhenAreWeMeetingComponent from "@/components/MidweekComponents/WhenAreWeMeetingComponent.vue";

export default {
  name: "MidweekView",
  components: {
    PageTitleComponent,
    ConnectComponent,
    WhenAreWeMeetingComponent,
  },
};
</script>

<style scoped></style>
