<template>
  <div id="mosaic-component">
    <div id="mosaic-content">
      <picture>
        <source srcset="@/assets/images/frames/mosaic-kids.webp" />
        <img src="@/assets/images/frames/mosaic-kids.png" alt="" />
      </picture>
      <div id="info-container">
        <h2>mosaic</h2>
        <p>
          Currently the children stay with us for the first part of the Morning
          service, enjoying sung worship and have the opportunity to use bubbles
          and streamers, we encourage them to sing (and dance!) along with the
          adults. During the sermon slot they have the oppurtunity to go to our
          Sunday club called Mosaic. Children who want to can join this group,
          held in the upper room, where there will be opportunity to have fun,
          sing, learn and grow in their faith.
          <br /><br />
          On the first Sunday of the month, we have Café Style Church where we
          all sit round tables and learn, discuss and often create together.
          This service style has a lovely family feel.
          <br /><br />
          We are very keen as a Church to nurture our children and keep them
          safe in Church so we have a robust Safeguarding Policy and Procedures
          and ask all parents / carer’s to fill in a registration form if their
          child wants to join our Sunday Club. Please click on the safeguarding
          button for further information.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MosaicComponent",
};
</script>

<style scoped>
#mosaic-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--background-light);
}

#mosaic-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 4rem;
  width: 100%;
  max-width: 90rem;
}

#mosaic-content picture {
  position: relative;
  width: 40%;
  max-width: 32rem;
}

#mosaic-content picture img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
  width: 60%;
}

h2 {
  text-align: left;
}

@media screen and (max-width: 900px) {
  #mosaic-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #mosaic-component {
    padding: 4rem 2rem;
  }

  #mosaic-content {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    row-gap: 2rem;
  }

  #mosaic-content picture {
    width: 100%;
    max-width: 24rem;
  }

  #info-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  h2 {
    text-align: center;
  }
}
</style>
