<template>
  <div id="community-view">
    <PageTitleComponent title="community"></PageTitleComponent>
    <SingAlongWithEllieComponent></SingAlongWithEllieComponent>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/AppComponents/PageTitleComponent.vue";
import SingAlongWithEllieComponent from "@/components/CommunityComponents/SingAlongWithEllie.vue";

export default {
  name: "CommunityView",
  components: {
    PageTitleComponent,
    SingAlongWithEllieComponent,
  },
};
</script>

<style scoped></style>
