<template>
  <div id="what-is-membership-component">
    <div id="what-is-membership-content">
      <picture>
        <source srcset="@/assets/images/frames/hands.webp" />
        <img src="@/assets/images/frames/hands.png" alt="" />
      </picture>
      <div id="info-container">
        <h2>what is membership?</h2>
        <p>
          When someone becomes a follower of Jesus Christ, commits their life to
          His Lordship and affirms this in Baptism then we recognise that they
          become a member of God’s worldwide Church, joining with millions of
          other Christians as part of God’s great family on earth.
          <br /><br />
          As well as joining God’s worldwide Church we believe it is also
          helpful and healthy for each Christian to find a local group of
          believers who they can connect with, a local church where they can be
          encouraged in their faith, develop their gifts, given to them by God,
          and serve within and beyond the church, partnering with God as He
          brings His kingdom on earth.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhatIsMembershipComponent",
};
</script>

<style scoped>
#what-is-membership-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--background-light);
}

#what-is-membership-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 4rem;
  width: 100%;
  max-width: 90rem;
}

#what-is-membership-content picture {
  position: relative;
  width: 40%;
  max-width: 32rem;
}

#what-is-membership-content picture img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
  width: 60%;
}

h2 {
  text-align: left;
}

@media screen and (max-width: 900px) {
  #what-is-membership-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #what-is-membership-component {
    padding: 4rem 2rem;
  }

  #what-is-membership-content {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    row-gap: 2rem;
  }

  #what-is-membership-content picture {
    width: 100%;
    max-width: 24rem;
  }

  #info-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  h2 {
    text-align: center;
  }
}
</style>
