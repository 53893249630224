<template>
  <HeaderComponent></HeaderComponent>
  <div id="router-view-container">
    <router-view />
  </div>
  <ContactFormComponent></ContactFormComponent>
  <SiteMapComponent></SiteMapComponent>
  <FooterComponent></FooterComponent>
</template>

<script>
import HeaderComponent from "./components/AppComponents/HeaderComponent.vue";
import ContactFormComponent from "./components/AppComponents/ContactFormComponent.vue";
import SiteMapComponent from "./components/AppComponents/SiteMapComponent.vue";
import FooterComponent from "./components/AppComponents/FooterComponent.vue";
export default {
  components: {
    HeaderComponent,
    ContactFormComponent,
    SiteMapComponent,
    FooterComponent,
  },
};
</script>

<style>
/*=== STYLE RESET=== */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  background-color: unset;
  border: none;
  cursor: pointer;
  line-height: 1.4;
}

a {
  text-decoration: none;
  color: var(--text-dark);
}

img {
  display: block;
}

/*============*/
@font-face {
  font-family: bree-serif;
  src: url("@/fonts/BreeSerif-Regular.ttf");
}

@font-face {
  font-family: boldoa;
  src: url("@/fonts/Boldoa Mat.otf");
}

@font-face {
  font-family: Luna;
  src: url("@/fonts/Luna.ttf");
}

:root {
  --highlight: #891728;
  --grey-1: #ebecec;
  --grey-2: #b0b1b2;
  --grey-3: #393d3f;
  --grey-4: #1d1f21;
  --background-dark: #0a0a0b;
  --background-light: #fafafb;
  --text-dark: #1d1f21;
  --text-light: #fafafb;
}

html {
  font-size: 16px;
}

body {
  -webkit-text-size-adjust: 100%;
  background: var(--text-dark);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: bree-serif;
  color: var(--text-dark);
}

#app {
  font-family: bree-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: boldoa;
  font-size: 4rem;
  text-transform: uppercase;
  color: var(--text-light);
  line-height: 1;
  text-align: center;
}

h2 {
  position: relative;
  margin-bottom: 0.5rem;
  font-family: boldoa;
  font-size: 2.5rem;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
}

h2::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  height: 0.5rem;
  transform: translateX(-50%);
  background-color: var(--highlight);
  border-radius: 10rem;
}

h3 {
  font-family: boldoa;
  font-size: 1.5rem;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
}

h4 {
  font-family: boldoa;
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 1.4;
  text-align: center;
}

p {
  font-family: bree-serif;
  font-size: 1rem;
  line-height: 1.4;
}

.primary-button {
  padding: 1rem;
  background-color: var(--highlight);
  border-radius: 1rem;
  color: var(--text-light);
  font-family: boldoa;
  text-transform: uppercase;
}

.secondary-button {
  position: relative;
  padding-bottom: 0.5rem;
  color: var(--text-light);
  text-transform: capitalize;
}

.secondary-button:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  border-top: 0.25rem dashed var(--highlight);
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  p,
  button,
  a {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1000px) and (max-height: 500px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  p,
  button,
  a {
    font-size: 1rem;
  }
}
</style>
