<template>
  <div id="membership-view">
    <PageTitleComponent title="membership"></PageTitleComponent>
    <WhatIsMembershipComponent></WhatIsMembershipComponent>
    <WhatItMeansToUsComponent></WhatItMeansToUsComponent>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/AppComponents/PageTitleComponent.vue";
import WhatIsMembershipComponent from "@/components/MembershipComponents/WhatIsMembershipComponent.vue";
import WhatItMeansToUsComponent from "@/components/MembershipComponents/WhatItMeansToUsComponent.vue";

export default {
  name: "MembershipView",
  components: {
    PageTitleComponent,
    WhatIsMembershipComponent,
    WhatItMeansToUsComponent,
  },
};
</script>

<style scoped></style>
