<template>
  <div id="accessibility-component">
    <div id="accessibility-content">
      <picture>
        <source srcset="@/assets/images/frames/bridge-inside.webp" />
        <img src="@/assets/images/frames/bridge-inside.png" alt="" />
      </picture>
      <div id="info-container">
        <h2>accessibility</h2>
        <p>
          The inside of our building is modern and light with carpet and chairs
          which allows us to be flexible when we gather.
          <br /><br />
          A loop system is fitted in church, just switch your hearing aid to
          ‘T’. <br /><br />
          Car parking is available opposite the church in Solutions car park
          (Sundays only).
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccessibilityComponent",
};
</script>

<style scoped>
#accessibility-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--grey-3);
}

#accessibility-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 4rem;
  width: 100%;
  max-width: 90rem;
}

#accessibility-content picture {
  position: relative;
  width: 40%;
  max-width: 32rem;
}

#accessibility-content picture img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
  width: 60%;
}

h2,
p {
  color: var(--text-light);
}

h2 {
  text-align: left;
}

@media screen and (max-width: 900px) {
  #accessibility-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #accessibility-component {
    padding: 4rem 2rem;
  }

  #accessibility-content {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    row-gap: 2rem;
  }

  #accessibility-content picture {
    width: 100%;
    max-width: 24rem;
  }

  #info-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  h2 {
    text-align: center;
  }
}
</style>
