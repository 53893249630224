<template>
  <div id="social-view">
    <PageTitleComponent title="social"></PageTitleComponent>
    <ComingTogetherComponent></ComingTogetherComponent>
    <RecentEventsComponent></RecentEventsComponent>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/AppComponents/PageTitleComponent.vue";
import ComingTogetherComponent from "@/components/SocialComponents/ComingTogetherComponent.vue";
import RecentEventsComponent from "@/components/SocialComponents/RecentEventsComponent.vue";

export default {
  name: "SocialView",
  components: {
    PageTitleComponent,
    ComingTogetherComponent,
    RecentEventsComponent,
  },
};
</script>

<style scoped></style>
