<template>
  <div id="when-and-where-component">
    <div id="when-and-where-content">
      <picture>
        <source srcset="@/assets/images/frames/bridge-front.webp" />
        <img src="@/assets/images/frames/bridge-front.png" alt="" />
      </picture>
      <div id="info-container">
        <h2>when and where</h2>
        <p>
          Our Sunday Morning Service starts at 10.45am. Church is open from
          10.15am so if you arrive early you can have a cuppa before the service
          starts.
          <br /><br />
          You can find us on Mansfield Road in Sutton-In-Ashfield.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhenAndWhereComponent",
};
</script>

<style scoped>
#when-and-where-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--background-light);
}

#when-and-where-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 4rem;
  width: 100%;
  max-width: 90rem;
}

#when-and-where-content picture {
  position: relative;
  width: 40%;
  max-width: 32rem;
}

#when-and-where-content picture img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
  width: 60%;
}

h2 {
  text-align: left;
}

@media screen and (max-width: 900px) {
  #when-and-where-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #when-and-where-component {
    padding: 4rem 2rem;
  }

  #when-and-where-content {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    row-gap: 2rem;
  }

  #when-and-where-content picture {
    width: 100%;
    max-width: 24rem;
  }

  #info-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  h2 {
    text-align: center;
  }
}
</style>
