<template>
  <div id="sing-along-with-ellie-component">
    <div id="sing-along-with-ellie-content">
      <picture>
        <source srcset="@/assets/images/frames/sing-along-with-ellie.webp" />
        <img src="@/assets/images/frames/sing-along-with-ellie.png" alt="" />
      </picture>
      <div id="info-container">
        <h2>sing along with ellie</h2>
        <p>
          Singing for the Brain is an Alzheimer’s Society initiative. This group
          runs regularly at The Bridge Baptist Church and is hosted by Ellie.
          <br />
          <br />
          There is a warm welcome for everybody including those who are living
          with dementia and their family, friends and carer’s. Singing is lively
          and fun - there is a huge range of familiar old and new songs for
          everyone’s taste. Songbooks are provided along with refreshments.
        </p>
        <a
          href="https://www.facebook.com/singalongwithellie"
          target="_blank"
          class="primary-button"
        >
          find out more
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SingAlongWithEllieComponent",
};
</script>

<style scoped>
#sing-along-with-ellie-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--background-light);
}

#sing-along-with-ellie-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 4rem;
  width: 100%;
  max-width: 90rem;
}

#sing-along-with-ellie-content picture {
  position: relative;
  width: 40%;
  max-width: 32rem;
}

#sing-along-with-ellie-content picture img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
  width: 60%;
}

h2 {
  text-align: left;
}

@media screen and (max-width: 900px) {
  #sing-along-with-ellie-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #sing-along-with-ellie-component {
    padding: 4rem 2rem;
  }

  #sing-along-with-ellie-content {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    row-gap: 2rem;
  }

  #sing-along-with-ellie-content picture {
    width: 100%;
    max-width: 24rem;
  }

  #info-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  h2 {
    text-align: center;
  }
}
</style>
