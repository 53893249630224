<template>
  <div id="building-hire-component">
    <div id="building-hire-content">
      <picture>
        <source srcset="@/assets/images/frames/bridge-inside.webp" />
        <img
          src="@/assets/images/frames/bridge-inside.png"
          alt="The inside of the Bridge dressed with flowers and cloth for a wedding"
          loading="lazy"
          decoding="async"
        />
      </picture>
      <div id="info-container">
        <h2>building hire</h2>
        <p>
          We do hire out our buildings to the local community and organisations.
          Please get in touch to enquire about availability and costs.
        </p>
        <button class="primary-button" @click="scrollToContactForm">
          contact
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuildingHireComponent",
  methods: {
    scrollToContactForm() {
      const contactForm = document.querySelector("#contact-form-component");
      const headerBar = document.querySelector("header");

      const topOfElement = contactForm.offsetTop - headerBar.offsetHeight;
      window.scroll({ top: topOfElement, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
#building-hire-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--background-light);
}

#building-hire-content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 4rem;
  width: 100%;
  max-width: 90rem;
}

#building-hire-content picture {
  position: relative;
  width: 40%;
  max-width: 32rem;
}

#building-hire-content picture img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
  width: 60%;
}

h2 {
  text-align: left;
}

@media screen and (max-width: 900px) {
  #building-hire-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #building-hire-component {
    padding: 4rem 2rem;
  }

  #building-hire-content {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    row-gap: 2rem;
  }

  #building-hire-content picture {
    width: 100%;
    max-width: 24rem;
  }

  #info-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  h2 {
    text-align: center;
  }
}
</style>
