<template>
  <div id="mosaic-title-component">
    <picture>
      <source
        media="(max-width: 600px)"
        srcset="@/assets/graphics/splat-background-mobile.png"
      />
      <img src="@/assets/graphics/splat-background.png" alt="" />
    </picture>

    <div id="mosaic-title-content">
      <div id="title">
        <span>M</span><span>o</span><span>s</span><span>a</span><span>i</span
        ><span>c</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MosaicTitleComponent",
};
</script>

<style scoped>
#mosaic-title-component {
  display: flex;
  width: 100%;
  height: 30dvh;
  min-height: 20rem;
}

#mosaic-title-component img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  background-color: var(--grey-4);
  z-index: -1;
}

#mosaic-title-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

#title span {
  font-size: 4rem;
  font-family: Luna;
}

#title span:nth-child(1),
#title span:nth-child(5) {
  color: #2c5fab;
}

#title span:nth-child(2),
#title span:nth-child(6) {
  color: #f3c916;
}

#title span:nth-child(3) {
  color: #e93f40;
}

#title span:nth-child(4) {
  color: #1ea65c;
}

@media screen and (max-width: 600px) {
  #title span {
    font-size: 3.5rem;
    font-family: Luna;
  }
}
</style>
