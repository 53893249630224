<template>
  <div id="what-is-safeguarding-component">
    <div id="what-is-safeguarding-content">
      <picture>
        <source srcset="@/assets/images/frames/wooden-people.webp" />
        <img src="@/assets/images/frames/wooden-people.png" alt="" />
      </picture>
      <div id="info-container">
        <h2>what is safeguarding?</h2>
        <p>
          Safeguarding is the protection of adults and children from harm, abuse
          and neglect.
          <br /><br />
          We all have the same rights and expectations to independence, respect,
          choice, fulfilment of our ambitions, to be heard, included, and to
          have privacy and confidentiality. These expectations are central to
          the way in which we interact with each other in our life together.
          <br /><br />
          Safeguarding involves a range of activities aimed at promoting the
          individual’s fundamental right to be safe. These include making and
          maintaining safe environments for all, having processes to follow
          should something go wrong, and support for everyone involved.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhatIsSafeguardingComponent",
};
</script>

<style scoped>
#what-is-safeguarding-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--background-light);
}

#what-is-safeguarding-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 4rem;
  width: 100%;
  max-width: 90rem;
}

#what-is-safeguarding-content picture {
  position: relative;
  width: 40%;
  max-width: 32rem;
}

#what-is-safeguarding-content picture img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
  width: 60%;
}

h2 {
  text-align: left;
}

@media screen and (max-width: 900px) {
  #what-is-safeguarding-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #what-is-safeguarding-component {
    padding: 4rem 2rem;
  }

  #what-is-safeguarding-content {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    row-gap: 2rem;
  }

  #what-is-safeguarding-content picture {
    width: 100%;
    max-width: 24rem;
  }

  #info-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  h2 {
    text-align: center;
  }
}
</style>
