<template>
  <div id="recent-events-component">
    <div id="recent-events-content">
      <h2>recent events</h2>
      <div id="event-cards-container">
        <div class="event-card">
          <picture>
            <source
              srcset="@/assets/images/events/prayer-gathering-feb-2025.webp"
            />
            <img
              src="@/assets/images/events/prayer-gathering-feb-2025.png"
              alt=""
            />
          </picture>
          <div class="event-card-header">
            <span>1st Febuary 2025</span>
            <h3>prayer gathering</h3>
          </div>
          <p>
            We met at Dave and Elaine's house for a prayer gathering with
            sausage baps to fuel us!
          </p>
        </div>
        <div class="event-card">
          <picture>
            <source
              srcset="@/assets/images/events/christmas-social-2024.webp"
            />
            <img
              src="@/assets/images/events/christmas-social-2024.png"
              alt=""
            />
          </picture>
          <div class="event-card-header">
            <span>14th December 2024</span>
            <h3>christmas social</h3>
          </div>
          <p>
            Over 40 of us gathered for festive food and quizzes for the 2024
            Christmas social at The Bridge.
          </p>
        </div>
        <div class="event-card">
          <picture>
            <source srcset="@/assets/images/events/charity-stall-2024.webp" />
            <img src="@/assets/images/events/charity-stall-2024.png" alt="" />
          </picture>
          <div class="event-card-header">
            <span>7th December 2024</span>
            <h3>christmas charity stall</h3>
          </div>
          <p>
            The Bridge held a charity stall at Idlewells Market in
            Sutton-in-Ashfield. We raised over £700 for Great Ormond Street
            Hospital!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecentEventsComponent",
};
</script>

<style scoped>
#recent-events-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--grey-4);
}

#recent-events-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;
  width: 100%;
  max-width: 90rem;
}

h2 {
  color: var(--text-light);
}

#event-cards-container {
  display: flex;
  justify-content: center;
  column-gap: 4rem;
  row-gap: 4rem;
  flex-wrap: wrap;
  width: 100%;
}

.event-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  max-width: 32rem;
  padding: 2rem;
  background-color: var(--background-light);
  border-radius: 2rem;
  text-align: center;
}

.event-card picture {
  display: block;
  width: 100%;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  overflow: hidden;
}

.event-card picture img {
  width: 100%;
  height: 100%;
}

.event-card-header {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.event-card-header:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 120%;
  height: 0.25rem;
  transform: translateX(-50%);
  background-color: var(--highlight);
  border-radius: 10rem;
}

.event-card-header span {
  font-size: 0.8rem;
}

@media screen and (max-width: 1250px) {
  #event-cards-container {
    column-gap: 4rem;
  }
}

@media screen and (max-width: 900px) {
  #recent-events-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #recent-events-component {
    padding: 4rem 2rem;
  }
}

@media screen and (max-width: 750px) {
  #recent-events-component {
    padding: 4rem 1rem;
  }

  .event-card {
    padding: 1rem 1rem 2rem;
  }
}
</style>
