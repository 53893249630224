<template>
  <div id="a-bit-about-us-component">
    <div id="a-bit-about-us-content">
      <picture>
        <source srcset="@/assets/images/frames/bridge-outside.webp" />
        <img
          src="@/assets/images/frames/bridge-outside.png"
          alt="The front of the Bridge Baptist chuch"
          loading="lazy"
          decoding="async"
        />
      </picture>
      <div id="info-container">
        <h2>a bit about us</h2>
        <p>
          Located in Sutton-in-Ashfield, Nottingham, The Bridge Baptist Church
          is a close-knit community where everyone is welcome. With a
          down-to-earth approach, we embrace people from all walks of life,
          coming together to explore faith, share in fellowship, and make a
          positive difference in our local area.
        </p>
        <RouterLink to="/about" class="primary-button">
          find out more
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ABitAboutUsComponent",
};
</script>

<style scoped>
#a-bit-about-us-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--background-light);
}

#a-bit-about-us-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 4rem;
  width: 100%;
  max-width: 90rem;
}

#a-bit-about-us-content picture {
  position: relative;
  width: 40%;
  max-width: 32rem;
}

#a-bit-about-us-content picture img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
  width: 60%;
}

h2 {
  text-align: left;
}

@media screen and (max-width: 900px) {
  #a-bit-about-us-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #a-bit-about-us-component {
    padding: 4rem 2rem;
  }

  #a-bit-about-us-content {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    row-gap: 2rem;
  }

  #a-bit-about-us-content picture {
    width: 100%;
    max-width: 24rem;
  }

  #info-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  h2 {
    text-align: center;
  }
}
</style>
