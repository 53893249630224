<template>
  <div id="meet-the-team-component">
    <div id="meet-the-team-content">
      <h2>meet the team</h2>
      <div id="team-cards-container">
        <div class="team-card" data-name="elaineBurrows">
          <picture>
            <source srcset="@/assets/images/team/elaine-burrows.webp" />
            <img
              src="@/assets/images/team/elaine-burrows.png"
              alt=""
              loading="lazy"
              decoding="async"
            />
          </picture>
          <div class="team-member-title">
            <h3>rev. elaine burrows</h3>
            <h4>community & service lead</h4>
          </div>
          <p></p>
          <button class="secondary-button" @click="showModal">
            learn more
          </button>
        </div>
        <div class="team-card" data-name="brianTomlinson">
          <picture>
            <source srcset="@/assets/images/team/brian-tomlinson.webp" />
            <img
              src="@/assets/images/team/brian-tomlinson.webp"
              alt=""
              loading="lazy"
              decoding="async"
            />
          </picture>
          <div class="team-member-title">
            <h3>brian tomlinson</h3>
            <h4>elder / secretary</h4>
          </div>
          <p></p>
          <button class="secondary-button" @click="showModal">
            learn more
          </button>
        </div>
        <div class="team-card" data-name="sueGardner">
          <picture>
            <source srcset="@/assets/images/team/sue-gardner.webp" />
            <img
              src="@/assets/images/team/sue-gardner.png"
              alt=""
              loading="lazy"
              decoding="async"
            />
          </picture>
          <div class="team-member-title">
            <h3>sue gardner</h3>
            <h4>deacon / pastoral care</h4>
          </div>
          <p></p>
          <button class="secondary-button" @click="showModal">
            learn more
          </button>
        </div>
        <div class="team-card" data-name="derekGardner">
          <picture>
            <source srcset="@/assets/images/team/derek-gardner.webp" />
            <img
              src="@/assets/images/team/derek-gardner.png"
              alt=""
              loading="lazy"
              decoding="async"
            />
          </picture>
          <div class="team-member-title">
            <h3>derek gardner</h3>
            <h4>deacon / support liaison</h4>
          </div>
          <p></p>
          <button class="secondary-button" @click="showModal">
            learn more
          </button>
        </div>
      </div>
    </div>
    <div id="modal-container" class="visibility-hidden" @click="closeModal">
      <div id="modal">
        <button id="modal-close-button" title="Close modal">
          <img
            src="@/assets/utility/close-icon.svg"
            alt="Close modal"
            @click="closeModal"
            loading="lazy"
            decoding="async"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MeetTheTeamComponent",
  data() {
    return {
      teamBios: {
        elaineBurrows:
          "Elaine joined our Church in 2019, along with her husband David. She enjoys leading Sunday Services and Preaching, and supports us as we connect with our local community. She enjoys helping others connect with Jesus through prayer. <br><br>Elaine is an accredited Baptist Minister, currently self employed, working in training and mediation. She also works as a volunteer for the Alzheimer’s Society as a Dementia Ambassador and volunteers at Kings Mill Hospital.",
        brianTomlinson:
          "Brian has been a member of The Bridge for many years. His role encompasses both Elder & Secretary. <br><br>His background has been in engineering and managerial in a local manufacturing company, amongst his many secular interests are walking in Derbyshire, Computers, Reading and Holidaying with his wife and family.",
        sueGardner:
          "Sue with her husband Derek joined our fellowship some 12 years ago, recently Sue was added to our leadership team here as a Deacon.<br><br>Sue’s main interests are travelling, reading, sharing time with her family and grandchild, Whoops mustn’t forget about her cooking and her scrumptious cakes.",
        derekGardner:
          "Derek was originally bought up in a Baptist Church in Kent over some 40 years ago, he then moved to an A.O.G. Church in Huthwaite and has been a valued member of our Church for the last 12 years.<br><br>His background has been in can making in a local factory and his interests include, his family welfare, Football as well as all sports and walking. Derek loves organising quizzes, if pies are included – so much the better.",
      },
    };
  },
  mounted() {
    const teamCards = document.querySelectorAll(".team-card");

    teamCards.forEach((card) => {
      card.querySelector("p").innerHTML = this.truncateBio(
        this.teamBios[card.dataset.name]
      );
    });
  },
  beforeDestroyed() {},
  methods: {
    truncateBio(bio) {
      let truncBioArray = bio.split(" ").slice(0, 18);
      if (truncBioArray[17].includes(",") || truncBioArray[17].includes(".")) {
        truncBioArray = truncBioArray.slice(0, 17);
      }
      return truncBioArray.join(" ") + " ...";
    },
    showModal(e) {
      const modalContainer = document.querySelector("#modal-container");
      const modal = document.querySelector("#modal");

      while (modal.childNodes.length > 1) {
        modal.removeChild(modal.lastChild);
      }

      const duplicateCard = e.target.parentNode.cloneNode(true);
      duplicateCard.querySelector("p").innerHTML =
        this.teamBios[duplicateCard.dataset.name];
      Array.from(duplicateCard.children).forEach((node) => {
        modal.appendChild(node);
      });

      modalContainer.classList.remove("visibility-hidden");
    },
    closeModal(e) {
      const modalContainer = document.querySelector("#modal-container");
      const modalCloseButton = document.querySelector("#modal-close-button");

      if (e.target === modalContainer || e.target === modalCloseButton) {
        modalContainer.classList.add("visibility-hidden");
      }
    },
  },
};
</script>

<style scoped>
#meet-the-team-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--background-light);
}

#meet-the-team-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;
  width: 100%;
  max-width: 90rem;
}

#team-cards-container {
  display: flex;
  justify-content: center;
  column-gap: 8rem;
  row-gap: 4rem;
  flex-wrap: wrap;
}

.team-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  max-width: 18.5rem;
  text-align: center;
}

.team-card picture,
#modal picture {
  position: relative;
  width: 12rem;
}

.team-card picture img,
#modal img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.team-card button {
  color: var(--text-dark);
}

.visibility-hidden {
  visibility: hidden;
}

#modal-container {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  background-color: #00000099;
  z-index: 99;
  cursor: pointer;
}

#modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  width: 100%;
  max-width: 60rem;
  max-height: 100%;
  padding: 4rem;
  background-color: var(--background-light);
  border-radius: 2rem;
  text-align: center;
  cursor: default;
  overflow: scroll;
}

#modal .team-member-title {
  position: relative;
  padding-bottom: 0.5rem;
}

#modal .team-member-title:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 150%;
  height: 0.25rem;
  transform: translateX(-50%);
  background-color: var(--highlight);
  border-radius: 10rem;
}

#modal .secondary-button {
  display: none;
}

#modal-close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem;
}

#modal-close-button img {
  width: 2rem;
  height: 2rem;
  pointer-events: none;
}

@media screen and (max-width: 1250px) {
  #team-cards-container {
    column-gap: 4rem;
  }
}

@media screen and (max-width: 900px) {
  #meet-the-team-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #meet-the-team-component {
    padding: 4rem 2rem;
  }

  #modal-container {
    padding: 1rem;
  }

  #modal {
    row-gap: 1.5rem;
    padding: 2rem;
  }

  #modal .team-member-title:after {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  #modal-container {
    padding: 0.5rem;
  }

  #modal {
    height: 100%;
    padding: 1rem;
    border-radius: 2rem;
  }
}
</style>
