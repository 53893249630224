import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import ServiceView from "@/views/ServiceView.vue";
import ChildrenView from "@/views/ChildrenView.vue";
import MidweekView from "@/views/MidweekView.vue";
import SocialView from "@/views/SocialView.vue";
import SafeguardingView from "@/views/SafeguardingView.vue";
import MembershipView from "@/views/MembershipView.vue";
import GalleryView from "@/views/GalleryView.vue";
import CommunityView from "@/views/CommunityView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/service",
    name: "service",
    component: ServiceView,
  },
  {
    path: "/children",
    name: "children",
    component: ChildrenView,
  },
  {
    path: "/midweek",
    name: "midweek",
    component: MidweekView,
  },
  {
    path: "/social",
    name: "social",
    component: SocialView,
  },
  {
    path: "/safeguarding",
    name: "safeguarding",
    component: SafeguardingView,
  },
  {
    path: "/membership",
    name: "membership",
    component: MembershipView,
  },
  {
    path: "/gallery",
    name: "gallery",
    component: GalleryView,
  },
  {
    path: "/community",
    name: "community",
    component: CommunityView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
