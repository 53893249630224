<template>
  <div class="page-title-component">
    <picture>
      <source srcset="@/assets/images/empty-church.webp" />
      <img src="@/assets/images/empty-church.webp" alt="" />
    </picture>
    <div class="page-title-content">
      <h1>{{ title }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitleComponent",
  props: {
    title: String,
  },
};
</script>

<style scoped>
.page-title-component {
  position: relative;
  width: 100%;
  height: 30dvh;
  min-height: 10rem;
}

.page-title-component picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.page-title-component picture img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-title-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #00000099;
  backdrop-filter: blur(10px);
}

.page-title-content h1 {
  position: relative;
  margin-bottom: 0.5rem;
  font-family: boldoa;
  font-size: 4rem;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
}

.page-title-content h1::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  height: 0.5rem;
  transform: translateX(-50%);
  background-color: var(--highlight);
  border-radius: 10rem;
}
</style>
