<template>
  <footer>
    <div id="footer-content">
      <RouterLink id="home-button" to="/" title="Go to The Bridge home">
        <img
          id="logo"
          src="@/assets/logos/logo-red.png"
          alt="The Bridge home"
        />
      </RouterLink>
      <p>© Copyright The Bridge Baptist Church 2024</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
footer {
  position: relative;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--background-dark);
}

#footer-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  text-align: center;
}

#home-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  padding: 0.25rem;
  aspect-ratio: 1 / 1;
  border: 0.25rem solid var(--highlight);
  border-radius: 50%;
  background-color: var(--highlight);
  transition: all 0.2s ease;
}

#logo {
  width: 100%;
  filter: brightness(0) invert();
  transition: all 0.2s ease;
}

footer p {
  color: var(--text-light);
  font-size: 0.75rem;
}

@media (hover: hover) {
  #home-button:hover {
    background-color: unset;
  }
}

@media screen and (max-width: 600px) {
  footer {
    padding: 4rem 2rem;
  }
}
</style>
