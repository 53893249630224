<template>
  <div id="get-involved-component">
    <div id="get-involved-content">
      <h2>get involved</h2>
      <div id="action-cards-container">
        <div class="action-card">
          <div class="icon-container">
            <img
              src="@/assets/images/icons/pencil-icon.png"
              alt="a pencil drawining a line"
              loading="lazy"
              decoding="async"
            />
          </div>
          <div class="info-container">
            <h3>sunday club</h3>
            <h3>for children</h3>
          </div>
          <RouterLink to="/children" class="primary-button">
            learn more
          </RouterLink>
        </div>
        <div class="action-card">
          <div class="icon-container">
            <img
              src="@/assets/images/icons/clock-icon.png"
              alt="a clock"
              loading="lazy"
              decoding="async"
            />
          </div>
          <div class="info-container">
            <h3>main service</h3>
            <h3>10:45 sunday</h3>
          </div>
          <RouterLink to="/service" class="primary-button">
            learn more
          </RouterLink>
        </div>
        <div class="action-card">
          <div class="icon-container">
            <img
              src="@/assets/images//icons/book-icon.png"
              alt="a text message outline"
              loading="lazy"
              decoding="async"
            />
          </div>
          <div class="info-container">
            <h3>small groups</h3>
            <h3>meet regularly</h3>
          </div>
          <RouterLink to="/midweek" class="primary-button">
            learn more
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GetInvolvedComponent",
};
</script>

<style scoped>
#get-involved-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--grey-1);
}

#get-involved-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;
  width: 100%;
  max-width: 90rem;
}

#action-cards-container {
  display: flex;
  justify-content: center;
  column-gap: 8rem;
}

.action-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  min-width: 13rem;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  padding: 1rem;
  background-color: var(--grey-3);
  border-radius: 50%;
  overflow: hidden;
}

.icon-container img {
  width: 100%;
}

@media screen and (max-width: 1050px) {
  #action-cards-container {
    column-gap: 4rem;
  }
}

@media screen and (max-width: 900px) {
  #get-involved-component {
    padding: 4rem 4rem;
  }

  #action-cards-container {
    flex-wrap: wrap-reverse;
    row-gap: 4rem;
  }

  .action-card:nth-child(2) {
    order: 1;
  }
}

@media screen and (max-width: 750px) {
  #get-involved-component {
    padding: 4rem 2rem;
  }
}
</style>
