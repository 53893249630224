<template>
  <div id="children-view">
    <MosaicTitleComponent></MosaicTitleComponent>
    <MosaicComponent></MosaicComponent>
  </div>
</template>

<script>
import MosaicTitleComponent from "@/components/ChildrenComponents/MosaicTitleComponent.vue";
import MosaicComponent from "@/components/ChildrenComponents/MosaicComponent.vue";

export default {
  name: "ChildrenView",
  components: { MosaicComponent, MosaicTitleComponent },
};
</script>

<style scoped></style>
