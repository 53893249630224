<template>
  <div id="connect-component">
    <div id="connect-content">
      <picture>
        <source srcset="@/assets/images/frames/small-group.webp" />
        <img src="@/assets/images/frames/small-group.png" alt="" />
      </picture>
      <div id="info-container">
        <h2>connect</h2>
        <p>
          Connect is one of our small groups that meets midweek. We meet to
          connect with God and each other through study, fellowship and prayer.
          <br /><br />
          Our meetings take place in the upper room at Church and start at 10am
          - everyone is welcome to come along to this group.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConnectComponent",
};
</script>

<style scoped>
#connect-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--background-light);
}

#connect-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 4rem;
  width: 100%;
  max-width: 90rem;
}

#connect-content picture {
  position: relative;
  width: 40%;
  max-width: 32rem;
}

#connect-content picture img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
  width: 60%;
}

h2 {
  text-align: left;
}

@media screen and (max-width: 900px) {
  #connect-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #connect-component {
    padding: 4rem 2rem;
  }

  #connect-content {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    row-gap: 2rem;
  }

  #connect-content picture {
    width: 100%;
    max-width: 24rem;
  }

  #info-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  h2 {
    text-align: center;
  }
}
</style>
