<template>
  <div id="church-life-component">
    <div id="church-life-content">
      <h2>church life</h2>
      <div id="event-cards-container">
        <div class="event-card">
          <picture>
            <source
              srcset="@/assets/images/square-frames/jubilee-social.webp"
            />
            <img
              src="@/assets/images/square-frames/jubilee-social.png"
              alt="People gather around tables outdoors for tea and cakes, there is Union Jack bunting"
              loading="lazy"
              decoding="async"
            />
          </picture>
          <h3>events</h3>
          <p>
            Join us for a variety of laid-back gatherings that bring our
            community together and create lasting memories.
          </p>
          <RouterLink to="/social" class="secondary-button">
            learn more
          </RouterLink>
        </div>
        <div class="event-card">
          <picture>
            <source
              srcset="@/assets/images/square-frames/christmas-social.webp"
            />
            <img
              src="@/assets/images/square-frames/christmas-social.png"
              alt="People gather around tables indoors for tea and coffee, there is a christmas tree in the background"
              loading="lazy"
              decoding="async"
            />
          </picture>
          <h3>connect</h3>
          <p>
            Connect is our regular midweek small group. We focus on building
            friendships in a relaxed, welcoming atmosphere as we follow bible
            studies, teaching videos, and pray and discuss together.
          </p>
          <RouterLink to="/midweek" class="secondary-button">
            learn more
          </RouterLink>
        </div>
        <div class="event-card">
          <picture>
            <source srcset="@/assets/images/square-frames/school-play.webp" />
            <img
              src="@/assets/images/square-frames/school-play.png"
              alt="Children are seating watching a performance inside of the Bridge main hall"
              loading="lazy"
              decoding="async"
            />
          </picture>
          <h3>community</h3>
          <p>
            Join us when we partner with our local community. Theese events
            enrich our shared experiences, all in a welcoming atmosphere.
          </p>
          <RouterLink to="/community" class="secondary-button">
            learn more
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChurchLifeComponent",
};
</script>

<style scoped>
#church-life-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--grey-3);
}

#church-life-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;
  width: 100%;
  max-width: 90rem;
}

h2,
h3,
p {
  color: var(--text-light);
}

h3,
p {
  width: 15rem;
}

#event-cards-container {
  display: flex;
  justify-content: center;
  column-gap: 8rem;
  row-gap: 4rem;
  flex-wrap: wrap;
}

.event-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  max-width: 18.5rem;
  text-align: center;
}

.event-card picture {
  position: relative;
  width: 15rem;
  border-radius: 2rem;
  overflow: hidden;
}

.event-card picture img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 1250px) {
  #event-cards-container {
    column-gap: 4rem;
  }
}

@media screen and (max-width: 900px) {
  #church-life-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #church-life-component {
    padding: 4rem 2rem;
  }
}
</style>
