<template>
  <div id="what-it-means-to-us-component">
    <div id="what-it-means-to-us-content">
      <picture>
        <source srcset="@/assets/images/frames/love.webp" />
        <img src="@/assets/images/frames/love.png" alt="" />
      </picture>
      <div id="info-container">
        <h2>what it means to us</h2>
        <p>
          At the heart of The Bridge Baptist Church is a desire for God to build
          us into a healthy faith community where we can all encounter God.
          <br /><br />
          We practice a Covenantal form of membership, which we renew each year
          in a service of celebration and commitment.
          <br /><br />
          This kind of membership is all about relationships – our relationship
          with God and with each other. In its simplest form it is about walking
          well together and watching over each other as we all follow Jesus.
          <br /><br />
          If you would like to find out more about being part of the Church at
          The Bridge Baptist Church then please speak to one of the leadership
          Team.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhatItMeansToUsComponent",
};
</script>

<style scoped>
#what-it-means-to-us-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4rem 8rem;
  background-color: var(--grey-1);
}

#what-it-means-to-us-content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 4rem;
  width: 100%;
  max-width: 90rem;
}

#what-it-means-to-us-content picture {
  position: relative;
  width: 40%;
  max-width: 32rem;
}

#what-it-means-to-us-content picture img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
  width: 60%;
}

h2 {
  text-align: left;
}

@media screen and (max-width: 900px) {
  #what-it-means-to-us-component {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 750px) {
  #what-it-means-to-us-component {
    padding: 4rem 2rem;
  }

  #what-it-means-to-us-content {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    row-gap: 2rem;
  }

  #what-it-means-to-us-content picture {
    width: 100%;
    max-width: 24rem;
  }

  #info-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  h2 {
    text-align: center;
  }
}
</style>
